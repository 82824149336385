<template>
  <div class="add_bank_card">
    <div class="tips">
      <svg-icon icon-class="ts_icon" />
      <span>请绑定与账户名称显示一致的身份证办理的银行卡</span>
    </div>
    <main>
      <van-field
        v-model="settleAcctName"
        type="tel"
        label="账户名称"
        placeholder="请输入银行卡账户名称"
      />
      <van-field
        v-model="bankAcctNo"
        type="tel"
        label="银行卡号"
        placeholder="请输入银行卡号"
      />
      <van-field
        v-model="mobileNumber"
        type="tel"
        label="手机号"
        placeholder="请输入银行卡预留手机号"
        maxlength="11"
      />
      <van-field
        v-model="captcha"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        maxlength="6"
      >
        <template #button>
          <button v-if="codeShow" class="get_code" @click.prevent="getCode">
            发送验证码
          </button>
          <p v-else class="count_down">{{ codeCountDown }}s后重新获取</p>
        </template>
      </van-field>
      <div class="button">
        <van-button block round color="#009A4D" @click="submit"
          >同意绑定银行卡</van-button
        >
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "add-bank-card",

  data() {
    return {
      settleAcctName: "",
      bankAcctNo: "",
      mobileNumber: "",
      captcha: "",
      codeShow: true, //验证码显示
      codeCountDown: 59, //验证码倒计时
    };
  },
  computed: {
    ...mapState("user", ["userInfo", "electronicAcct", "bankCard", "isLogin"]),
  },
  watch: {},
  async created() {
    await this.$store.dispatch("user/queryElectronicAcct");
  },
  methods: {
    async submit() {
      //
      if (this.settleAcctName === "") {
        this.Toast.fail("请输入账户名称");
      } else if (!/^[1-9]\d{9,29}$/.test(this.bankAcctNo)) {
        this.Toast.fail("请输入正确的银行卡号码");
      } else if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.mobileNumber)) {
        this.Toast.fail("请输入正确的手机号码");
      } else if (this.captcha.length < 6) {
        this.Toast.fail("请输入六位验证码");
      } else {
        await this.$API.user
          .createSettleAcct({
            object: {
              bankAcctNo: this.bankAcctNo,
              bankBranchCode: "",
              bankBranchName: "",
              bankCode: "",
              bankName: "",
              mobileNumber: this.mobileNumber,
              serviceType: "1",
              settleAcctName: this.settleAcctName,
              smsCode: this.captcha,
              userId: this.userInfo.id,
            },
          })
          .then((res) => {
            if (res.data.code != "SUCCESS") throw Error(res.data.msg);
            this.Toast.success("绑卡成功");
            this.$router.go(-1);
          })
          .catch((e) => {
            this.Toast({
              type: "fail",
              message: e.message,
              duration: 5000,
              closeOnClick: true,
              overlay: true,
              closeOnClickOverlay: true,
            });
          });
      }
      //
    },
    async getCode() {
      if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.mobileNumber)) {
        this.Toast.fail("请输入正确的手机号码");
        return;
      }
      this.Toast.loading({
        duration: 0,
        message: "发送中...",
        forbidClick: true,
      });
      await this.$API.user
        .sendSmsCodesByMobile({
          mobileNumber: this.mobileNumber,
          serviceType: "1",
        })
        .then((res) => {
          this.Toast.clear();
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.Toast.success("发送成功");
          this.codeShow = false;
          let t = setInterval(() => {
            this.codeCountDown--;
            if (this.codeCountDown <= 0) {
              this.codeShow = true;
              clearInterval(t);
              this.codeCountDown = 29;
            }
          }, 1000);
        })
        .catch((e) => {
          this.Toast.fail(e.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add_bank_card {
  padding-top: 46px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  .tips {
    color: #f99c10;
    padding: 8px 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .svg-icon {
      margin-right: 8px;
    }
  }
  main {
    flex: 1;
    background: #fff;
    .get_code {
      width: 76px;
      height: 24px;
      background: #ffffff;
      border-radius: 13px 13px 13px 13px;
      opacity: 1;
      border: 1px solid #0787f4;
      font-size: 12px;
      color: #0787f4;
    }
    .count_down {
      height: 24px;
      background: #f6f6f6;
      border-radius: 13px 13px 13px 13px;
      font-size: 12px;
      color: #999;
      padding: 0 8px;
    }
    .button {
      padding: 0 15px;
      ::v-deep .van-button {
        margin-top: 40px;
      }
    }
  }
}
</style>
